<template>
  <div id="app">
    <!-- Nav -->
    <AppNav />
    
    <!-- Main -->
    <router-view/>

    <!-- Footer -->
    <AppFooter />
  </div>
</template>

<script lang="ts">
import AppNav from '@/components/common/AppNav.vue'
import AppFooter from '@/components/common/AppFooter.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    AppNav,
    AppFooter
  },
})
export default class App extends Vue {}
</script>
<template>
  <div class="parallel app-content">
    <div class="frame">
      <iframe
          src="https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc1155.html?contract=0x289378348F9D34301102af710fEE693a7A52573e&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fpolygon.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22MATIC%22%2C%22symbol%22%3A%22MATIC%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Fpolygon%2F512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=04aca2109c45f09c01668d8354ff8780&tokenId=0&theme=dark&primaryColor=orange"
          width="600px"
          height="600px"
          style="max-width:100%;"
          frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HomeView extends Vue {}
</script>

<style lang="scss">
.parallel {
  display: flex;
  flex-direction: column;

  .frame {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    iframe {
      margin: 0 auto;
    }
  }
}
</style>